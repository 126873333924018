<meic-dialog>
	@if (permissions$ | async; as permissions) {
		<meic-checkbox-select
			orientation="vertical"
			[formControl]="permissionsControl"
			[options]="permissionsToOptions(permissions)"
			[numeric]="true"
		/>
	}
	@if (isLoading$ | async) {
		<meic-loading />
	}
	<div class="dialog__actions">
		<button
			type="button"
			class="button button_outlined"
			(click)="closeDialogWithResult(false)"
		>
			Cancel
		</button>
		<button
			type="submit"
			class="button"
			[meicLoading]="isSavingPermissions$ | async"
			(click)="onSubmit()"
		>
			Save Changes
		</button>
	</div>
</meic-dialog>
