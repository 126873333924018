<meic-dialog>
	<form
		(ngSubmit)="onSubmit()"
		[formGroup]="form"
	>
		<div class="profile">
			<div class="profile__picture">
				<div class="profile__image-container">
					<img
						[src]="pictureControl.value"
						alt=""
					/>
				</div>
				<span class="profile__change-picture typography-button-link">
					Change profile picture
					<input
						class="profile__picture-input"
						(change)="onFileSelected($event.target)"
						type="file"
						accept="image/*"
					/>
				</span>
			</div>
			<div class="profile__form">
				<meic-label labelText="Name">
					<input
						formControlName="fullName"
						autocomplete="name"
						type="text"
					/>
				</meic-label>
				<meic-label labelText="Email">
					<input
						formControlName="email"
						type="email"
						autocomplete="email"
					/>
				</meic-label>
				<meic-label labelText="Password">
					<input
						type="text"
						value="************"
						disabled
					/>
				</meic-label>
				<span
					class="typography-button-link profile__action"
					(click)="onChangePassword()"
				>
					Change password
				</span>
				<meic-label labelText="Company">
					<input
						type="text"
						formControlName="company"
					/>
				</meic-label>
				@if (permissionService.canEditUsers$ | async) {
					<meic-label labelText="Role">
						<mat-select formControlName="roleId">
							@for (role of roles$ | async; track role.id) {
								<mat-option [value]="role.id">
									{{ role.description }}
								</mat-option>
							}
						</mat-select>
					</meic-label>
					@if (userData$ | async; as userData) {
						<span
							(click)="editPermissions()"
							class="typography-button-link profile__action"
							*ngIf="form.controls.roleId.value === userData.role?.id"
						>
							Advance settings
						</span>
					}
					<meic-label labelText="Location">
						<mat-select formControlName="locationId">
							@for (location of location$ | async; track location.id) {
								<mat-option [value]="location.id">
									{{ location.name }}
								</mat-option>
							}
						</mat-select>
					</meic-label>
					@if (availableLocations$ | async; as locations) {
						@if (locations.length > 0) {
							<div class="profile__location">Manage Regional Office Locations</div>
							<ul>
								<ul>
									@for (location of locations; track location.id) {
										<li class="profile__location-item">{{ location.name }}</li>
									}
								</ul>
							</ul>
						}
					}
				}
			</div>
		</div>
		<div class="dialog__actions">
			<button
				type="button"
				class="button button_outlined"
				(click)="closeDialogWithResult(false)"
			>
				Cancel
			</button>
			<button
				type="submit"
				class="button"
				[meicLoading]="isLoading$ | async"
			>
				Save
			</button>
		</div>
	</form>
</meic-dialog>
