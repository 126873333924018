import { AsyncPipe, CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, FormsModule, NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { Location } from '@mei/common/core/models/location';
import { User, UserEdit, userEditSchema } from '@mei/common/core/models/user';
import { UserRole, UserRoleType } from '@mei/common/core/models/user-role';
import { DialogData, DialogService } from '@mei/common/core/services/dialog.service';
import { LocationService } from '@mei/common/core/services/location.service';
import { NotificationService } from '@mei/common/core/services/notification.service';
import { PermissionApiService } from '@mei/common/core/services/permission-api.service';
import { UserApiService } from '@mei/common/core/services/user-api.service';
import { UserService } from '@mei/common/core/services/user.service';
import { filterNull } from '@mei/common/core/utils/rxjs/filter-null';
import { toggleExecutionState } from '@mei/common/core/utils/rxjs/toggle-execution-state';
import { secureParse } from '@mei/common/core/utils/secureParse';
import { FlatControlsOf } from '@mei/common/core/utils/types/controls-of';
import { StrictOmit } from '@mei/common/core/utils/types/strict-omit';
import { DialogComponent } from '@mei/common/shared/components/dialog/dialog.component';
import { LabelComponent } from '@mei/common/shared/components/label/label.component';
import { LoadingDirective } from '@mei/common/shared/directives/loading.directive';
import { BehaviorSubject, combineLatest, EMPTY, filter, first, ignoreElements, merge, Observable, shareReplay, switchMap, tap } from 'rxjs';
import { listenControlChanges } from '@mei/common/core/utils/rxjs/listen-control-changes';
import { z } from 'zod';
import { PermissionService } from '@mei/common/core/services/permission.service';

import { PermissionDialogData, PermissionsDialogComponent } from '../permissions-dialog/permissions-dialog.component';

/** User profile dialog data. */
export type ProfileDialogData = DialogData & {

	/** User ID. If use ID is null, that means the profile dialog is of the current user.*/
	readonly userId?: User['id'];
};

type UserEditFormData = FlatControlsOf<StrictOmit<UserEdit, 'roleId' | 'locationId'> & {

	/** Role ID. */
	readonly roleId: number | null;

	/** Location ID. */
	readonly locationId: number | null;

	/** User company. */
	readonly company: string;
}>;

/** Profile dialog. */
@Component({
	selector: 'meiw-profile-dialog',
	templateUrl: './profile-dialog.component.html',
	styleUrl: './profile-dialog.component.css',
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		DialogComponent,
		LabelComponent,
		AsyncPipe,
		LoadingDirective,
		MatSelectModule,
		ReactiveFormsModule,
		FormsModule,
		CommonModule,
	],
})
export class ProfileDialogComponent extends DialogComponent<ProfileDialogData> implements OnInit {

	private readonly permissionApiService = inject(PermissionApiService);

	private readonly dialogService = inject(DialogService);

	private readonly destroyRef = inject(DestroyRef);

	private readonly userApiService = inject(UserApiService);

	private readonly userService = inject(UserService);

	private readonly locationService = inject(LocationService);

	private readonly fb = inject(NonNullableFormBuilder);

	private readonly notificationService = inject(NotificationService);

	private readonly isEditingCurrentUser = this.dialogData.userId == null;

	/** Permission service. */
	protected readonly permissionService = inject(PermissionService);

	/** Picture control. */
	protected readonly pictureControl = new FormControl('');

	/** User data. */
	protected readonly userData$: Observable<User>;

	/** Whether form is loading. */
	protected readonly isLoading$ = new BehaviorSubject(false);

	/** All user roles. */
	protected readonly roles$: Observable<UserRole[]>;

	/** Locations. */
	protected readonly location$: Observable<Location[]>;

	/** User form. */
	protected readonly form: FormGroup<UserEditFormData>;

	/** All available locations. */
	protected readonly availableLocations$ = new BehaviorSubject<Location[]>([]);

	public constructor() {
		super();
		this.roles$ = this.permissionApiService.getRoles();
		this.location$ = this.locationService.getAll().pipe(
			shareReplay({ bufferSize: 1, refCount: true }),
		);
		this.form = this.initializeForm();
		this.userData$ = (
			this.dialogData.userId ?
				this.userApiService.getDetail(this.dialogData.userId) :
				this.userService.currentUser$
		).pipe(
			filterNull(),
			shareReplay({ refCount: true, bufferSize: 1 }),
		);
	}

	/** @inheritdoc */
	public ngOnInit(): void {
		merge(
			this.fillFormSideEffect(),
			this.updateSameLocationsSideEffect(),
			this.disableFormSideEffect(),
		).pipe(
			takeUntilDestroyed(this.destroyRef),
		)
			.subscribe();
	}

	/** Edit permissions. */
	protected editPermissions(): void {
		this.dialogService.openDialogWith<PermissionsDialogComponent, PermissionDialogData, boolean>(
			PermissionsDialogComponent,
			{
				title: 'Advanced Settings',
				userId: this.dialogData.userId,
			},
		).pipe(
			takeUntilDestroyed(this.destroyRef),
		)
			.subscribe();
	}

	private initializeForm(): FormGroup<UserEditFormData> {
		return this.fb.group<UserEditFormData>({
			fullName: this.fb.control('', Validators.required),
			email: this.fb.control('', Validators.required),
			locationId: this.fb.control(null),
			roleId: this.fb.control(null),
			profilePicture: this.fb.control(null),
			company: this.fb.control({ value: '', disabled: true }),
		});
	}

	/** Handle form submission. */
	protected onSubmit(): void {
		this.permissionService.canEditUsers$.subscribe({
			next: canEditUser => {
				if (canEditUser) {
					this.editUser();
					return;
				}
				if (this.isEditingCurrentUser) {
					this.editProfilePicture();
				}
			},
		});
	}

	private editProfilePicture(): void {
		const profilePicture = this.form.controls.profilePicture.getRawValue();
		if (profilePicture == null) {
			this.closeDialogWithResult(true);
			return;
		}
		this.userApiService.editProfilePicture(profilePicture).pipe(
			takeUntilDestroyed(this.destroyRef),
		)
			.subscribe({
				next: () => {
					this.userService.refresh();
					this.closeDialogWithResult(true);
				},
			});
	}

	private editUser(): void {
		this.form.markAllAsTouched();
		if (this.form.invalid) {
			return;
		}
		const formData = secureParse(this.form.getRawValue(), userEditSchema);
		this.userData$.pipe(
			switchMap(user => this.userApiService.edit(formData, user.id).pipe(
				toggleExecutionState(this.isLoading$),
			)),
			takeUntilDestroyed(this.destroyRef),
		)
			.subscribe({
				next: () => {
					this.userService.refresh();
					this.closeDialogWithResult(true);
				},
			});
	}

	private fillFormSideEffect(): Observable<void> {
		return this.userData$.pipe(
			first(),
			tap(user => {
				this.form.patchValue({
					fullName: user.fullName,
					email: user.email,
					locationId: user.location?.id,
					roleId: user.role?.id,
					company: user.company?.name ?? '',
				});
				this.pictureControl.setValue(this.getPictureUrl(user.profilePicture));
			}),
			ignoreElements(),
		);
	}

	private disableFormSideEffect(): Observable<void> {
		return this.permissionService.canEditUsers$.pipe(
			filter(canEdit => !canEdit),
			tap(() => {
				this.form.controls.fullName.disable();
				this.form.controls.email.disable();
				this.form.controls.locationId.disable();
				this.form.controls.roleId.disable();
			}),
			ignoreElements(),
		);
	}

	/**
	 * Handle "change" event of input type=file.
	 * @param eventTarget Event target.
	 */
	public onFileSelected(eventTarget: EventTarget | null): void {
		if (eventTarget instanceof HTMLInputElement && eventTarget.files?.[0]) {
			const file = eventTarget.files[0];
			this.form.controls.profilePicture.setValue(file);
			this.pictureControl.setValue(URL.createObjectURL(file));
			return;
		}
		throw new Error('Incorrect target for method handling');
	}

	/** Handle change password. */
	protected onChangePassword(): void {
		this.userData$
			.pipe(
				switchMap(user => this.userService.forgotPassword(user.email)),
				takeUntilDestroyed(this.destroyRef),
			)
			.subscribe({
				next: () => this.notificationService.notify('Please check your email for change password link!'),
			});
	}

	private updateSameLocationsSideEffect(): Observable<void> {
		return combineLatest([
			this.userData$,
			listenControlChanges(this.form.controls.locationId, z.number().nullable()),
		]).pipe(
			switchMap(([user, locationId]) => {
				if (user.role == null || locationId == null) {
					return EMPTY;
				}
				if (user.role.type !== UserRoleType.Company && user.role.type !== UserRoleType.Region) {
					return EMPTY;
				}
				return this.locationService.getSameLocations(locationId);
			}),
			tap(locations => this.availableLocations$.next(locations)),
			ignoreElements(),
		);
	}

	/**
	 * Because the profile URL picture of a user is not changed after they edit it.
	 * But the browser caches the profile picture each time it gets changed.
	 * So we will add a unique string after the URL to prevent it.
	 * Ref: https://stackoverflow.com/questions/126772/how-to-force-a-web-browser-not-to-cache-images.
	 * @param url Picture url.
	 */
	protected getPictureUrl(url: string): string {
		return `${url}?${new Date().getTime()
			.toString()}`;
	}
}
