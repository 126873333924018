import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, map } from 'rxjs';

import { User } from '../models/user';

import { AppErrorMapper } from '../mappers/app-error.mapper';

import { AppUrlsConfig } from './app-urls.config';

/** Masquerade service. */
@Injectable({
	providedIn: 'root',
})
export class MasqueradeService {
	private readonly httpClient = inject(HttpClient);

	private readonly appUrlsConfig = inject(AppUrlsConfig);

	private readonly errorMapper = inject(AppErrorMapper);

	/**
	 * Enter masquerade mode.
	 * @param id User id.
	 **/
	public enter(id: User['id']): Observable<void> {
		return this.httpClient.post(this.appUrlsConfig.masquerade.enter(id), {}).pipe(
			map(() => undefined),
			this.errorMapper.catchHttpErrorToAppError(),
		);
	}

	/** Leave masquerade mode. */
	public leave(): Observable<void> {
		return this.httpClient.delete(this.appUrlsConfig.masquerade.leave).pipe(
			map(() => undefined),
			this.errorMapper.catchHttpErrorToAppError(),
		);
	}
}
