import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { Option } from '@mei/common/core/models/option';
import { Permission } from '@mei/common/core/models/permission';
import { User } from '@mei/common/core/models/user';
import { DialogData } from '@mei/common/core/services/dialog.service';
import { NotificationService } from '@mei/common/core/services/notification.service';
import { PermissionApiService } from '@mei/common/core/services/permission-api.service';
import { UserService } from '@mei/common/core/services/user.service';
import { filterNull } from '@mei/common/core/utils/rxjs/filter-null';
import { toggleExecutionState } from '@mei/common/core/utils/rxjs/toggle-execution-state';
import { secureParse } from '@mei/common/core/utils/secureParse';
import { CheckboxSelectComponent } from '@mei/common/shared/components/checkbox-select/checkbox-select.component';
import { DialogComponent } from '@mei/common/shared/components/dialog/dialog.component';
import { LoadingComponent } from '@mei/common/shared/components/loading/loading.component';
import { LoadingDirective } from '@mei/common/shared/directives/loading.directive';
import { BehaviorSubject, Observable, first, ignoreElements, map, of, shareReplay, switchMap, tap } from 'rxjs';
import { z } from 'zod';

/** Permission dialog data. */
export type PermissionDialogData = DialogData & {

	/** User ID. If use ID is undefined, that means the permission dialog is of the current user.*/
	readonly userId?: User['id'];
};

/** Permissions dialog. */
@Component({
	selector: 'meiw-permissions-dialog',
	templateUrl: './permissions-dialog.component.html',
	styleUrl: './permissions-dialog.component.css',
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		AsyncPipe,
		DialogComponent,
		LoadingDirective,
		CheckboxSelectComponent,
		LoadingComponent,
		ReactiveFormsModule,
	],
})
export class PermissionsDialogComponent extends DialogComponent<PermissionDialogData> implements OnInit {
	private readonly permissionService = inject(PermissionApiService);

	private readonly userService = inject(UserService);

	private readonly destroyRef = inject(DestroyRef);

	private readonly notificationService = inject(NotificationService);

	private readonly userId$: Observable<User['id']>;

	/** Whether is loading permissions. */
	protected readonly isLoading$ = new BehaviorSubject(false);

	/** Whether permissions is saving. */
	protected readonly isSavingPermissions$ = new BehaviorSubject(false);

	/** User permissions. */
	protected readonly permissions$: Observable<Permission[]>;

	/** Permissions control. */
	protected readonly permissionsControl = new FormControl<Permission['id'][]>([]);

	public constructor() {
		super();
		this.permissions$ = this.permissionService.getList().pipe(
			toggleExecutionState(this.isLoading$),
			shareReplay({ refCount: true, bufferSize: 1 }),
		);
		this.userId$ = this.dialogData.userId ? of(this.dialogData.userId) : this.userService.currentUser$.pipe(
			filterNull(),
			map(user => user.id),
		);
	}

	/** @inheritdoc */
	public ngOnInit(): void {
		this.initializeFormValue().pipe(
			takeUntilDestroyed(this.destroyRef),
		)
			.subscribe();
	}

	/**
	 * Maps permissions to options.
	 * @param permissions Permissions.
	 */
	protected permissionsToOptions(permissions: Permission[]): Option[] {
		return permissions.map(permission => ({
			value: permission.id,
			label: permission.description,
		}));
	}

	private initializeFormValue(): Observable<void> {
		return this.userId$.pipe(
			first(),
			switchMap(id => this.permissionService.getUserPermissions(id)),
			map(permissions => permissions.map(permission => permission.id)),
			tap(permissionIds => this.permissionsControl.patchValue(permissionIds)),
			ignoreElements(),
		);
	}

	/** Handle form submission. */
	protected onSubmit(): void {
		const permissions = secureParse(this.permissionsControl.getRawValue(), z.string().array());
		this.userId$.pipe(
			first(),
			switchMap(id => this.permissionService.save(id, permissions).pipe(
				this.notificationService.notifyOnAppError(),
				toggleExecutionState(this.isSavingPermissions$),
			)),
			takeUntilDestroyed(this.destroyRef),
		)
			.subscribe({
				complete: () => this.closeDialogWithResult(true),
			});
	}
}
